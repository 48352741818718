<div class="keyboard-wrap alto-keyboard">
  <div class="keyboard-container">
    <input type="text" placeholder="Start typing..." [(ngModel)]="inputValue" readonly>

    <div class="keyboard">
      <div class="keyboard-row">
        <button class="key small grey"></button>
        <button 
          *ngFor="let key of keyRows[0]" 
          class="key" 
          (click)="onKeyPress(key.char)">
          <span class="main-char">{{ key.char }}</span>
          <span class="sub-char">{{ key.symbol }}</span>
        </button>
        <button class="key wide grey backspace-top" (click)="onKeyPress('backspace')"><img src="assets/icons/delete-left.svg" alt="Delete"></button>
      </div>

      <div class="keyboard-row justify-content-end">
        <button class="key wide grey"></button>
        <button 
          *ngFor="let key of keyRows[1]" 
          class="key" 
          (click)="onKeyPress(key.char)">
          <span class="main-char">{{ key.char }}</span>
          <span class="sub-char">{{ key.symbol }}</span>
        </button>
        <button class="key extrawide grey"></button>
      </div>

      <div class="keyboard-row">
        <button class="key extrawide grey"></button>
        <button 
          *ngFor="let key of keyRows[2]" 
          class="key" 
          (click)="onKeyPress(key.char)">
          <span class="main-char">{{ key.char }}</span>
          <span class="sub-char">{{ key.symbol }}</span>
        </button>
        <button class="key wide grey flex-fill"></button>
      </div>

      <div class="keyboard-row justify-content-end">
        <button class="key extrawide grey flex-fill" (click)="toggleNumSymbol()">.?123</button>
        <button class="key space" (click)="onKeyPress(' ')"></button>
        <button class="key wide grey" (click)="toggleNumSymbol()">.?123</button>
        <button class="key wide grey" (click)="onClose()">
          <img src="assets/icons/keyboard-down.svg" alt="Close">
        </button>
      </div>
    </div>
  </div>
</div>
